<template>
  <div v-if="photos && photos.length !== 0">
    <v-layout row wrap justify-space-between class="position-relative">
      <v-flex
        v-if="photos[0]"
        :id="`provider-image-${vehicleType}-preview-1`"
        :style="$vuetify.breakpoint.smAndUp ? 'padding-right:6px;' : ''"
        xs12
        sm8
        @click="openModal(photos[0])"
      >
        <div
          class="preview label-box overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          :class="!photos[1] ? 'show-all-photos-box' : ''"
        >
          <p
            class="position-absolute top-0 font-14 padding-y-1 padding-x-3 text-white z-5"
          >
            {{ photos[0].label }}
          </p>
          <v-img
            class="hover:transform-102 transition-all transition-duration-300"
            height="100%"
            aspect-ratio="1.6"
            :src="photos[0].imagePath"
          />
          <p
            v-if="!photos[1]"
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click="openModal(photos[0])"
          >
            Show all photos
          </p>
        </div>
      </v-flex>

      <v-flex
        xs12
        sm4
        :style="$vuetify.breakpoint.smAndUp ? 'padding-left:6px;' : ''"
      >
        <div
          v-if="photos[1]"
          class="preview overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          :class="!photos[2] ? 'show-all-photos-box' : ''"
          @click="openModal(photos[1])"
        >
          <v-img
            :id="`provider-image-${vehicleType}-preview-2`"
            class="hover:transform-102 transition-all transition-duration-300"
            aspect-ratio="1.65"
            height="100%"
            :src="photos[1].imagePath"
          />
          <p
            v-if="!photos[2]"
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click.stop="openModal(photos[0])"
          >
            Show all photos
          </p>
        </div>
        <div
          v-if="photos[2]"
          class="preview show-all-photos-box overflow-hidden margin-y-3 margin-x-0 cursor-pointer position-relative"
          @click="openModal(photos[2])"
        >
          <v-img
            :id="`provider-image-${vehicleType}-preview-3`"
            class="hover:transform-102 transition-all transition-duration-300"
            aspect-ratio="1.65"
            height="100%"
            :src="photos[2].imagePath"
          />
          <p
            class="position-absolute bottom-0 right-0 text-right font-16 font-medium text-decoration-underline text-white padding-y-1 padding-x-3 z-5"
            @click.stop="openModal(photos[0])"
          >
            Show all photos
          </p>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    photos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    vehicleType() {
      return this.photos && this.photos[0] && this.photos[0].vehicleTypeDTO
        ? this.photos[0].vehicleTypeDTO.label
        : ''
    },
  },
  methods: {
    openModal(image) {
      this.$emit('open-modal', image)
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  &.label-box {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 35%
      );
      opacity: 0.75;
    }
    &.show-all-photos-box {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0) 35%,
          rgba(0, 0, 0, 0) 65%,
          rgba(0, 0, 0, 1) 100%
        );
        opacity: 0.75;
      }
    }
  }

  &.show-all-photos-box {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0) 35%
      );
      opacity: 0.75;
    }
  }
}
</style>
