<template>
  <article :style="`border-bottom: 1px solid ${$cr.theme.borderGray}`">
    <div class="d-flex align-center">
      <CRIcon
        class="margin-r-2 max-w-24"
        view-box="0 -960 960 960"
        color="yellow"
      >
        verified
      </CRIcon>
      <h3>Certified Partner</h3>
    </div>
    <p>
      CharterUP vetted this operator for reliability, service excellence, and
      fair pricing.
    </p>
  </article>
</template>

<script>
export default {
  components: {},
}
</script>
