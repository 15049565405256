<template>
  <article :style="`border-bottom: 1px solid ${$cr.theme.borderGray}`">
    <h3>Insurance</h3>
    <p>
      {{ insurancePolicy }}
    </p>
  </article>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    defaultInsurancePolicy:
      'GOGO Charters is fully insured up to $10 million. In the case of an accident, they provide a maximum coverage of $5 million per trip.',
  }),
  computed: {
    insurancePolicy() {
      return this.company.insurancePolicy || this.defaultInsurancePolicy
    },
  },
}
</script>
