<template>
  <article :style="`border-bottom: 1px solid ${$cr.theme.borderGray}`">
    <h3>Bus Rules</h3>
    <p v-if="rules && rules.length > 0">
      <v-layout row wrap dense>
        <v-flex
          v-for="(rule, ruleIndex) in rules"
          :key="`${rule.busRuleTypeId}-${ruleIndex}`"
          xs6
          class="min-w-300"
        >
          <v-layout row wrap align-center justify-start>
            <v-flex shrink>
              <v-icon small left :style="{ top: '-1px' }">
                {{ convertRuleKeyToIcon(rule.busRuleType.icon) }}
              </v-icon>
            </v-flex>
            <v-flex shrink>
              {{ rule.busRuleType.label }}
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </p>
    <p v-else>No information has been provided.</p>
  </article>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rules() {
      return this.company.companyBusRules.filter((rule) => rule.supported)
    },
  },
  methods: {
    convertRuleKeyToIcon() {
      return 'not_interested'
    },
  },
}
</script>
