var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Amenities")]),(_vm.amenities && _vm.amenities.length)?_c('p',[_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.amenities),function(amenity,amenityIndex){return _c('v-flex',{key:`${amenity.id}-${amenityIndex}`,attrs:{"xs6":"","sm4":""}},[_c('div',{staticClass:"d-flex align-center text-left"},[_c('div',{staticClass:"margin-r-2",class:{
              'w-16 h-16 max-w-16': amenity.key !== 'spab',
              'w-24 h-24 max-w-24': amenity.key === 'spab',
            }},[_c('CRIcon',{style:({
                marginTop: '-1px',
                marginLeft: amenity.key !== 'spab' ? 0 : '-4px',
              }),attrs:{"view-box":"0 0 24 24","width":amenity.key !== 'spab' ? 16 : 24,"height":amenity.key !== 'spab' ? 16 : 24,"left":""}},[_vm._v(" "+_vm._s(_vm.convertAmenityKeyToCRIcon(amenity.key))+" ")])],1),_vm._v(" "+_vm._s(amenity.label)+" ")])])}),1)],1):_c('p',[_vm._v("No information provided.")])])
}
var staticRenderFns = []

export { render, staticRenderFns }