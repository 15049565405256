var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticStyle:{"margin-top":"36px","margin-left":"4px","border":"none"}},[_vm._v(" More bus companies nearby ")]),(_vm.$cr.breakpoint.lgAndUp && _vm.bids)?_c('v-slide-group',{staticStyle:{"margin-top":"18px"},style:(_vm.computedSliderClass),attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.bids),function(bid,i){return _c('v-slide-item',{key:i},[_c('BidCard',{staticStyle:{"padding-right":"8px","padding-left":"8px","margin-top":"13px"},attrs:{"id":`provider-bidcard-${i}`,"stacked":"","imgwidth":400,"bid":bid,"go-to":{
          name: 'charterup-provider-detail',
          params: {
            quoteId: _vm.quoteId,
            providerId: bid.companyId,
          },
        }}})],1)}),1):_c('div',{staticStyle:{"margin-top":"18px","margin-bottom":"80px"}},_vm._l((_vm.bids),function(bid,i){return _c('BidCard',{key:i,staticStyle:{"margin-bottom":"12px"},attrs:{"id":`provider-bidcard-${i}`,"stacked":!_vm.$cr.breakpoint.smAndUp,"button":_vm.$cr.breakpoint.md,"imgwidth":_vm.$cr.breakpoint.smAndUp ? 220 : 340,"bid":bid,"go-to":{
        name: 'charterup-provider-detail',
        params: {
          quoteId: _vm.quoteId,
          providerId: bid.companyId,
        },
      }}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }