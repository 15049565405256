<template>
  <div>
    <h3>Amenities</h3>

    <p v-if="amenities && amenities.length">
      <v-layout row wrap>
        <v-flex
          v-for="(amenity, amenityIndex) in amenities"
          :key="`${amenity.id}-${amenityIndex}`"
          xs6
          sm4
        >
          <div class="d-flex align-center text-left">
            <div
              class="margin-r-2"
              :class="{
                'w-16 h-16 max-w-16': amenity.key !== 'spab',
                'w-24 h-24 max-w-24': amenity.key === 'spab',
              }"
            >
              <CRIcon
                view-box="0 0 24 24"
                :width="amenity.key !== 'spab' ? 16 : 24"
                :height="amenity.key !== 'spab' ? 16 : 24"
                left
                :style="{
                  marginTop: '-1px',
                  marginLeft: amenity.key !== 'spab' ? 0 : '-4px',
                }"
              >
                {{ convertAmenityKeyToCRIcon(amenity.key) }}
              </CRIcon>
            </div>

            {{ amenity.label }}
          </div>
        </v-flex>
      </v-layout>
    </p>

    <p v-else>No information provided.</p>
  </div>
</template>

<script>
import { convertAmenityKeyToCRIcon } from '@/utils/string'

export default {
  props: {
    amenities: {
      type: Array,
      required: true,
    },
  },
  methods: {
    convertAmenityKeyToCRIcon,
  },
}
</script>
