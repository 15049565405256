<template>
  <v-card :key="`vehicle-${index}-card`" class="margin-y-4">
    <v-card-text class="padding-y-5">
      <v-row>
        <v-col cols="12" class="padding-b-2">
          <h2 class="font-medium">
            {{ title }}
          </h2>
        </v-col>
      </v-row>
      <p v-if="isCharterBus && addedAmenities.length" class="margin-y-3">
        Added extras
      </p>
      <div class="d-flex align-center flex-wrap">
        <div
          v-for="(amenity, amenityIndex) in addedAmenities"
          :key="`amenity-${amenityIndex}`"
          class="padding-y-2 padding-r-2"
        >
          <CRIcon
            class="cursor-default margin-r-2"
            color="primary"
            view-box="0 0 24 24"
            width="20px"
            height="20px"
            style="vertical-align: middle"
          >
            {{ amenity.icon }}
          </CRIcon>
          <span style="vertical-align: middle">
            {{ amenity.title }}
          </span>
        </div>
      </div>
      <div v-if="isCharterBus && includedAmenities.length" class="margin-t-6">
        <p class="font-14">
          Bathroom, luggage storage, TV, and outlets are available on most
          Charter Buses.
        </p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { VehicleTypeId } from '@/utils/enum'
export default {
  name: 'CheckoutAmenityCardItem',
  props: {
    vehicleId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    amenities: {
      type: Array,
      required: true,
    },
  },
  computed: {
    includedAmenities() {
      return this.amenities.filter((amenity) => !!amenity.included)
    },
    addedAmenities() {
      return this.amenities.filter((amenity) => !amenity.included)
    },
    isCharterBus() {
      return this.vehicleId === VehicleTypeId.CharterBus
    },
  },
}
</script>
