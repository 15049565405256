<template>
  <div class="carousel">
    <div class="img-box">
      <span class="image-overlay">
        <p v-if="currentLabel">{{ currentLabel }}</p>
        <img :src="currentImage.imagePath" />
      </span>
    </div>
    <v-layout row align-center justify-center>
      <div
        v-for="(thumbnail, itemIndex) in visibleThumbnails"
        :key="`vehiclePhoto-${itemIndex}`"
      >
        <img
          class="image-thumbnail"
          :src="thumbnail.imagePath"
          :class="
            photos[value].vehiclePhotoCharterUpId ===
            thumbnail.vehiclePhotoCharterUpId
              ? 'image-thumbnail--selected'
              : ''
          "
          @click="selectPhoto(thumbnail)"
        />
      </div>
    </v-layout>

    <v-layout
      row
      class="arrows"
      align="stretch"
      :justify="$cr.breakpoint.mdAndUp ? 'center' : 'space-around'"
    >
      <v-flex
        id="carousel-arrow-left"
        xs5
        class="d-flex justify-center"
        style="cursor: pointer"
        @click="handleArrowKey({ keyCode: 37 })"
      >
        <v-icon color="gray">keyboard_arrow_left</v-icon>
      </v-flex>
      <v-flex
        :xs1="$cr.breakpoint.mdAndUp"
        :xs2="!$cr.breakpoint.mdAndUp"
        class="d-flex justify-center"
      >
        {{ `${value + 1} / ${photos.length}` }}
      </v-flex>
      <v-flex
        id="carousel-arrow-right"
        class="d-flex justify-center"
        xs5
        style="cursor: pointer"
        @click="handleArrowKey({ keyCode: 39 })"
      >
        <v-icon color="gray">keyboard_arrow_right</v-icon>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selected_width: 100,
      RIGHT_KEY: 39,
      LEFT_KEY: 37,
      ESC_KEY: 27,
    }
  },
  computed: {
    currentImage() {
      return this.photos?.[this.value]
    },
    currentLabel() {
      return this.currentImage.label
    },
    thumbnailCount() {
      if (this.$cr.breakpoint.xl) {
        return 10
      }
      if (this.$cr.breakpoint.lg || this.$cr.breakpoint.md) {
        return 6
      }
      if (this.$cr.breakpoint.sm) {
        return 4
      }
      return 3
    },
    visibleThumbnails() {
      if (this.photos.length <= this.thumbnailCount) {
        return this.photos
      }
      if (this.photos.length - this.value >= this.thumbnailCount) {
        return this.photos.slice(this.value, this.value + this.thumbnailCount)
      }
      const backfillCount =
        this.thumbnailCount - (this.photos.length - this.value)
      const backfill = this.photos.slice(this.value - backfillCount, this.value)
      const frontfill = this.photos.slice(this.value, this.photos.length)
      return backfill.concat(frontfill)
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleArrowKey)
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleArrowKey)
  },
  methods: {
    handleArrowKey(e) {
      if (e.keyCode === this.RIGHT_KEY) {
        if (this.value >= this.photos.length - 1) {
          this.$emit('input', 0)
        } else {
          this.$emit('input', this.value + 1)
        }
      } else if (e.keyCode === this.LEFT_KEY) {
        if (this.value <= 0) {
          this.$emit('input', this.photos.length - 1)
        } else {
          this.$emit('input', this.value - 1)
        }
      }
    },
    selectPhoto(photo) {
      this.$emit('input', this.photos.indexOf(photo))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.carousel {
  padding: 20px;
  padding-top: 0;
}

.arrows {
  padding-top: 20px;
  .v-icon {
    max-width: 25px;
  }
}

.image-thumbnail {
  cursor: pointer;
  transition: transform 0.3s;
  margin: 10px;
  width: 200px;
  height: 113px;
  object-fit: cover;

  &:hover {
    transform: scale(1.02);
  }
  &--selected {
    outline: $primary solid 2px;
  }
}

.img-box {
  position: relative;
  display: flex;
  justify-content: center;

  span {
    position: relative;

    &.image-overlay {
      &:after {
        content: '';
        position: absolute;
        border-radius: 10px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: inline-block;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0) 25%
        );
        opacity: 0.75;
      }
    }
  }

  p {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 2;
    color: white;
  }

  img {
    height: 68vh;
    max-width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
</style>
