var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.providerDetail)?_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{staticClass:"sheet",attrs:{"column":""}},[_c('v-icon',{staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.$router.push({
          name: 'quotes.view',
          params: {
            id: _vm.quoteDetail.quoteId,
            subRoute: 'bids',
          },
        })}}},[_vm._v(" arrow_back ")]),_c('StickyColumnLayout',{scopedSlots:_vm._u([{key:"left-column",fn:function(){return [_c('ProviderInfo',{attrs:{"provider-detail":_vm.providerDetail}})]},proxy:true},{key:"right-column",fn:function(){return [_c('BillingSummary',_vm._b({staticStyle:{"margin-top":"24px"},attrs:{"button":"","quote":_vm.quoteDetail},on:{"click":function($event){return _vm.$router.push({
              name: 'charterup-checkout',
              params: {
                quoteId: _vm.$route.params.quoteId,
                providerId: _vm.$route.params.providerId,
              },
            })}}},'BillingSummary',_vm.providerDetail,false)),_c('CheckoutAmenitiesCard',{attrs:{"is-self-serve":_vm.isSelfServe,"amenities":_vm.amenities,"vehicles":_vm.vehicles}}),_c('TripCard',{style:(_vm.$cr.breakpoint.lgAndUp ? 'margin-top: -9px;' : ''),attrs:{"itinerary":"","map":_vm.$cr.breakpoint.mdAndUp,"customer-notes":"","trips":_vm.providerDetail.quote.trips,"created-on":_vm.providerDetail.quote.createdOn}})]},proxy:true},{key:"bottom-portion",fn:function(){return [_c('BidCardCarousel',{attrs:{"quote-detail":_vm.quoteDetail}})]},proxy:true}],null,false,356738407)}),_c('MobileFooter',{attrs:{"provider-detail":_vm.providerDetail},on:{"click":function($event){return _vm.$router.push({
          name: 'charterup-checkout',
          params: {
            quoteId: _vm.$route.params.quoteId,
            providerId: _vm.$route.params.providerId,
          },
        })}}})],1)],1):(_vm.notFound)?_c('v-container',{staticClass:"fill-height"},[_c('NotFound')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }