
import { Amenity } from '@/models/Amenity';
import { AmenityTypeId, VehicleTypeId } from './enum'

const AVAILABLE_AMENITIES: Partial<Record<AmenityTypeId, Amenity>> = {
  [AmenityTypeId.Bathroom]: {
    id: AmenityTypeId.Bathroom,
    icon: 'bathroom',
    title: 'Bathroom',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Luggage]: {
    id: AmenityTypeId.Luggage,
    icon: 'luggage',
    title: 'Luggage',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Outlets]: {
    id: AmenityTypeId.Outlets,
    icon: '',
    title: 'Outlets',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.TVScreens]: {
    id: AmenityTypeId.TVScreens,
    icon: '',
    title: 'TV Screen',
    color: 'primary',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Wifi]: {
    id: AmenityTypeId.Wifi,
    icon: 'wifi',
    title: 'Wifi',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.LeatherSeats]: {
    id: AmenityTypeId.LeatherSeats,
    icon: 'airline_seat_recline_extra',
    title: 'Leather Seats',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus, VehicleTypeId.MiniBus],
  },
  [AmenityTypeId.AlcoholConsumption]: {
    id: AmenityTypeId.AlcoholConsumption,
    icon: 'liquor',
    title: 'Alcohol Allowed',
    color: 'greenSuccess',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus, VehicleTypeId.MiniBus],
  },
}

/**
 * Retrieves available amenities based on the given vehicle type ID.
 *
 * @param {AmenityTypeId[]} amenities - An array of amenity type IDs to filter from.
 * @param {VehicleTypeId} vehicleTypeId - The vehicle type ID to filter amenities by.
 * @returns {AmenityDetails[]} An array of amenities that are available for the given vehicle type.
 */
export const getAvailableAmenitiesByVehicleTypeId = (
  amenities: AmenityTypeId[],
  vehicleTypeId: VehicleTypeId
): Amenity[] => {
  if (!amenities || !vehicleTypeId) {
    return [];
  }
  const availableAmenities: Amenity[] = [];
  for (const amenity of amenities) {
    const amenityDetail = AVAILABLE_AMENITIES[amenity];
    if (amenityDetail && amenityDetail.vehicleTypeIds.includes(vehicleTypeId)) {
      availableAmenities.push(amenityDetail);
    }
  }
  return availableAmenities;
}


/**
 * Gets the icon name for a given amenity type ID.
 *
 * @param {AmenityTypeId} amenityTypeId - The amenity type ID to get the icon for.
 * @returns {string} The icon name if found; otherwise, an empty string.
 */
export const getAmenityIcon = (amenityTypeId: AmenityTypeId): string => {
  return AVAILABLE_AMENITIES.hasOwnProperty(amenityTypeId)
    ? AVAILABLE_AMENITIES[amenityTypeId].icon
    : '';
}

export const quoteFormAmenities = [
  {
    id: 1,
    key: 'wifi',
    label: 'Wifi'
  },
  {
    id: 4,
    key: 'seat_belts',
    label: 'Seat belts',
  },
  {
    id: 5,
    key: 'ada',
    label: 'ADA',
  },
  {
    id: 6,
    key: 'tv_screens',
    label: 'TV Screens/DVD',
  },
  {
    id: 8,
    key: 'leather_seats',
    label: 'Leather Seats'
  },
  {
    id: 11,
    key: 'spab',
    label: 'SPAB',
  },
  {
    id: 12,
    key: 'alcohol_allowed',
    label: 'Alcohol Allowed'
  },
  {
    id: 13,
    key: 'mountain_driver',
    label: 'Mountain Driver',
  },
  {
    id: 14,
    key: '19a_certification',
    label: '19A Certification',
  },
  {
    id: 15,
    key: 'aux_bluetooth',
    label: 'Aux/Bluetooth',
  },
  {
    id: 16,
    key: 'pa_system',
    label: 'PA System',
  },
  {
    id: 17,
    key: 'bottled_water',
    label: 'Bottled Water',
  },
  {
    id: 18,
    key: 'make_model_year',
    label: 'Make/Model/Year of the Bus',
  },
  {
    id: 19,
    key: 'vehicle_color',
    label: 'Color of the Interior/Exterior of the Bus',
  },
  {
    id: 20,
    key: 'vehicle_logos',
    label: 'Logos or Lack Thereof on the Exterior of the Bus',
  },
  {
    id: 21,
    key: 'non_standard_vehicle_size',
    label: 'Non-Standard Bus Size/Passenger Count',
  },
  {
    id: 22,
    key: 'trip_coordinators',
    label: 'Trip Coordinators',
  },
  {
    id: 23,
    key: 'wraps',
    label: 'Bus Wraps',
  }
]
