<template>
  <v-container>
    <v-layout row fluid wrap align="start" justify="center" no-gutters>
      <v-flex lg7 md9>
        <slot name="left-column" />
      </v-flex>
      <v-flex
        v-if="$cr.breakpoint.lgAndUp"
        sm12
        class="trip-info"
        lg5
        style="align-items: flex-start; padding-left: 46px"
      >
        <slot name="right-column" />
      </v-flex>
    </v-layout>

    <v-flex lg12>
      <slot name="bottom-portion" />
    </v-flex>
  </v-container>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '1250px',
    },
  },
}
</script>

<style>
.trip-info {
  position: -webkit-sticky;
  position: sticky;
  top: 4.5rem;
}
</style>
