<template>
  <article class="provider-detail--section">
    <h3>
      Company Info
      <template v-if="company.dotVerified">
        <CRIcon
          :color="'#00a6f2'"
          :view-box="'0 0 24 24'"
          :width="18"
          :height="18"
          style="margin-bottom: -3px; margin-left: 8px"
        >
          check_circle
        </CRIcon>
        <span
          style="
            font-family: 'Airbnb Cereal App Book';
            font-size: 16px !important;
          "
        >
          DOT Verified
        </span>
      </template>
    </h3>
    <v-layout class="company-facts">
      <v-flex v-if="company.milesLastYear !== null" class="company-facts__item">
        <p class="company-facts__item__title">Miles Last Year</p>
        <p class="company-facts__item__value">
          {{ numberWithCommas(company.milesLastYear) }}
        </p>
      </v-flex>
      <v-flex
        v-if="company.accidentsLastYear !== null"
        class="company-facts__item"
      >
        <p class="company-facts__item__title">Accidents Last Year</p>
        <p class="company-facts__item__value">
          {{ company.accidentsLastYear }}
        </p>
      </v-flex>
      <v-flex
        v-if="company.onTimePerformance !== null"
        class="company-facts__item"
      >
        <p class="company-facts__item__title">On Time Performance</p>
        <p class="company-facts__item__value">
          {{ company.onTimePerformance }}%
        </p>
      </v-flex>
      <v-flex v-if="company.yearFounded" class="company-facts__item">
        <p class="company-facts__item__title">Year Company Started</p>
        <p class="company-facts__item__value">
          {{ company.yearFounded }}
        </p>
      </v-flex>
    </v-layout>
    <p>
      {{ companyDescription }}
      <span v-if="!showAll" class="details-toggle" @click="showAll = true">
        See More
      </span>
    </p>
    <img v-if="showAll && fleetPhoto" class="fleet-photo" :src="fleetPhoto" />
    <p
      v-if="showAll"
      class="details-toggle"
      style="text-align: right"
      @click="showAll = false"
    >
      Less
    </p>
    <div class="provider-compliance-list">
      <v-layout
        v-for="(compliance, complianceIndex) in complianceList"
        :key="`${compliance.key}-${complianceIndex}`"
        class="provider-compliance"
        align-center
      >
        <span class="provider-compliance--icon">
          <CRIcon
            :view-box="compliance.viewBox"
            :height="compliance.height"
            :width="compliance.width"
          >
            {{ compliance.icon }}
          </CRIcon>
        </span>
        <v-flex>
          <h4>{{ compliance.title }}</h4>
          <p>{{ compliance.description }}</p>
        </v-flex>
      </v-layout>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
}
</script>

<script>
import { numberWithCommas } from '@/utils/string'
import { AmenityTypeId } from '@/utils/enum'
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
      numberWithCommas,
    }
  },
  computed: {
    complianceList() {
      return [
        {
          key: 'dod',
          compliant: this.isSupportedByAmenityTypeId(AmenityTypeId.DOD),
          title: 'Department of Defense Approved',
          description:
            'This provider has been approved for Department of Defense transportation.',
          icon: 'falcon',
          viewBox: '0 0 40 26',
          height: 26,
          width: 40,
        },
        {
          key: 'ada',
          compliant: this.isSupportedByAmenityTypeId(AmenityTypeId.ADA),
          title: 'ADA Compliant Vehicles',
          description:
            'This provider has vehicles compliant with the Americans with Disabilities Act (ADA).',
          icon: 'wheelchair',
          viewBox: '0 0 26 31',
          height: 31,
          width: 26,
        },
        {
          key: 'cdc',
          compliant: this.isSupportedByAmenityTypeId(AmenityTypeId.CDC),
          title: 'Follows CDC Health Guidelines',
          description:
            'This provider follows the recommended guidelines from the CDC regarding COVID-19.',
          icon: 'thermometer',
          viewBox: '0 0 20 33',
          height: 33,
          width: 20,
        },
        {
          key: 'spab',
          compliant: this.isSupportedByAmenityTypeId(AmenityTypeId.SPAB),
          title: 'School Pupil Activity Bus Certified',
          description:
            "This provider's drivers and vehicles have been SPAB certified.",
          icon: 'school',
          viewBox: '0 0 24 24',
          height: 34,
          width: 34,
        },
      ].filter((compliance) => compliance.compliant)
    },
    companyDescription() {
      if (this.company.description) {
        if (this.showAll) {
          return this.company.description
        }
        const wordCount = this.company.description.split(' ').length
        if (wordCount > 25) {
          return `${this.company.description
            .split(' ')
            .slice(0, 25)
            .join(' ')}...`
        }

        return this.company.description
      }
      return 'No information has been provided.'
    },
    fleetPhoto() {
      const fleetPhoto = this.company.charterupCompanyPhotos.find(
        (companyPhoto) => {
          return companyPhoto.isFleetImage
        }
      )
      if (fleetPhoto) {
        return fleetPhoto.imagePath
      }
      return null
    },
  },
  methods: {
    isSupportedByAmenityTypeId(amenityTypeId) {
      return !!this.company.companyAmenities.find(
        (amenity) =>
          amenity.amenityTypeId === amenityTypeId && amenity.supported
      )
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.provider-detail--section {
  border-bottom: 1px solid $border-gray;
}

.company-facts {
  margin-top: 8px;
  margin-bottom: 8px;

  &__item {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    padding: 12px;

    &:not(:last-child) {
      border-right: 1px solid $border-gray;
    }
    &__title {
      font-size: 14px;
      color: $gray-light !important;
      line-height: 1.2em;
    }
    &__value {
      font-family: 'Airbnb Cereal App Medium';
    }
  }
}

.details-toggle {
  color: $primary !important;
  font-family: 'Airbnb Cereal App Medium';
  cursor: pointer;
}

.provider-compliance {
  margin: 1em 0;

  &--icon {
    width: 80px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .v-flex {
    padding: 0;
  }

  h4 {
    margin: 0;
    line-height: 1;
  }

  p {
    margin-top: 0;
    color: gray !important;
    font-size: 14px !important;
  }
}

.fleet-photo {
  margin-top: 16px;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
}
</style>
