<template>
  <div v-if="isSelfServe">
    <div
      v-for="(vehicle, index) in groupedVehicles"
      :key="`vehicle-amenity-card-${index}`"
    >
      <CheckoutAmenityCardItem
        :vehicle-id="vehicle.id"
        :title="vehicle.title"
        :amenities="vehicle.vehicleAmenities"
      />
    </div>
  </div>
</template>

<script>
import CheckoutAmenityCardItem from '@/components/charterup/CheckoutAmenityCardItem.vue'
import { getAvailableAmenitiesByVehicleTypeId } from '@/utils/amenity'
import { VehicleTypeId } from '@/utils/enum'

export default {
  name: 'CheckoutAmenitiesCard',
  components: {
    CheckoutAmenityCardItem,
  },
  props: {
    isSelfServe: {
      type: Boolean,
      required: true,
    },
    amenities: {
      type: Array,
      required: true,
    },
    vehicles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      vehicleTypeIds: [VehicleTypeId.CharterBus, VehicleTypeId.MiniBus],
    }
  },
  computed: {
    groupedVehicles() {
      return this.vehicleTypeIds.reduce(this.groupVehicles, [])
    },
  },
  methods: {
    groupVehicles(vehicleAcc, vehicleTypeId) {
      const hasVehicle = this.vehicles.some(
        (vehicle) => vehicle.vehicleTypeId === vehicleTypeId
      )
      if (hasVehicle) {
        const vehicleAmenities = getAvailableAmenitiesByVehicleTypeId(
          this.amenities,
          vehicleTypeId
        )
        vehicleAcc.push({
          id: vehicleTypeId,
          title: this.getVehicleTitle(vehicleTypeId),
          vehicleAmenities,
        })
      }
      return vehicleAcc
    },
    getVehicleTitle(vehicleTypeId) {
      switch (vehicleTypeId) {
        case VehicleTypeId.CharterBus:
          return 'Charter Bus Amenities'
        case VehicleTypeId.MiniBus:
          return 'Mini Bus Amenities'
        default:
          return 'Amenities'
      }
    },
  },
}
</script>
