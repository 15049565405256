<template>
  <v-card style="border-radius: 16px;">
    <v-card-text>
      <v-layout wrap dense style="margin-bottom: 18px; font-size: 16px">
        <v-flex xs8>Base Fare</v-flex>
        <v-spacer />
        <v-flex xs4>
          {{ baseFare | currencyFilter }}
        </v-flex>
        <template v-if="paidAmenities && totalAmenityAmount">
          <v-flex xs8>{{ paidAmenities }}</v-flex>
          <v-spacer />
          <v-flex xs4>
            {{ totalAmenityAmount | currencyFilter }}
          </v-flex>
        </template>
        <v-flex xs8>Processing Fee</v-flex>
        <v-spacer />
        <v-flex xs4>
          {{ processingFeeAmount | currencyFilter }}
        </v-flex>
        <v-flex v-if="discountPercent" class="discount-line-item" xs8>
          Discount
          <span v-if="quote.trips.length === 1">
            ({{ Math.abs(discountPercent) }}%)
          </span>
        </v-flex>
        <v-spacer v-if="discountPercent" class="discount-line-item" />
        <v-flex v-if="discountPercent" class="discount-line-item" xs4>
          {{ discountAmount | currencyFilter }}
        </v-flex>
        <v-flex xs8>
          <h2 style="font-size: 16px; border: none;">Booking Total (USD)</h2>
        </v-flex>
        <v-spacer />
        <v-flex xs4>
          <h2 style="font-size: 16px; border: none;">
            {{ totalAmount | currencyFilter }}
          </h2>
        </v-flex>
      </v-layout>

      <div v-if="amountDueEventually" style="margin-bottom: 12px">
        <v-divider style="margin-bottom: 18px" />

        <v-layout no-gutters>
          <v-flex xs8>
            <h2 style="font-size: 16px; border: none;">
              <span>
                {{ alreadyBooked ? 'Remaining Balance' : 'Due Now' }}
              </span>
            </h2>
          </v-flex>
          <v-spacer />
          <v-flex xs4>
            <h2 style="font-size: 16px; border: none;">
              <span>
                {{ amountDueNow | currencyFilter }}
              </span>
            </h2>
          </v-flex>
        </v-layout>

        <v-layout v-if="amountDueEventually" row no-gutters>
          <v-flex xs8>
            <span>Due on {{ `${dueDate} ${paymentTermDays}` }}</span>
          </v-flex>
          <v-spacer />
          <v-flex xs4>
            <span>
              {{ amountDueEventually | currencyFilter }}
            </span>
          </v-flex>
        </v-layout>
      </div>
      <v-btn
        v-if="button"
        id="billing-summary-button-checkout"
        block
        large
        depressed
        style="margin: 28px 0 12px 0"
        class="charterup-primary-btn"
        @click="$emit('click')"
      >
        <h2 style="color: white; font-size: 20px; border: none;">
          Continue to Booking
        </h2>
      </v-btn>
      <div v-if="!isInCheckout">
        <v-divider class="margin-t-6" />
        <v-layout row justify-start class="margin-t-n1 margin-b-2">
          <div>
            <CRIcon
              class="cursor-default"
              viewBox="0 0 24 24"
              width="35"
              height="35"
            >
              verified_user
            </CRIcon>
          </div>
          <div class="margin-t-n2 margin-l-4">
            <h5 class="booking-protection-header">
              Booking Protection Guarantee
            </h5>
            <InfoModal :modal-detail="bookingProtectionModalDetail">
              <template #activator="{ on, attrs }">
                <div
                  class="learn-more-text cursor-pointer margin-t-n2 margin-b-n4"
                  v-bind="attrs"
                  v-on="on"
                >
                  Learn more
                </div>
              </template>
            </InfoModal>
          </div>
        </v-layout>
      </div>
      <v-row
        v-if="isDriverHotelRequired"
        align="start"
        class="margin-t-4 font-14"
        style="display: flex"
      >
        <CRIcon
          view-box="0 0 24 24"
          color="black"
          width="16px"
          height="16px"
          class="margin-r-2 cursor-default"
          style="margin-top: 2px; flex-shrink: 0"
        >
          hotel
        </CRIcon>
        <p class="margin-t-0" style="flex-shrink: 1">
          The customer is responsible for the driver's hotel accommodations for
          overnight trips.
        </p>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import { currencyFilter } from '@/utils/string'
import { RiskTypeId } from '@/utils/enum'
import InfoModal from './InfoModal.vue'
import customers from '@/services/customers'
import { bookingProtectionModalDetail } from '@/utils/infoModal'

export default {
  name: 'BillingSummary',
  filters: {
    currencyFilter,
  },
  components: {
    InfoModal,
  },
  inject: {
    isInCheckout: {
      default: false,
    },
  },
  props: {
    totalAmount: {
      type: Number,
      default: null,
    },
    amountDueNow: {
      type: Number,
      default: null,
    },
    amountDueEventually: {
      type: Number,
      default: null,
    },
    subTotalAmount: {
      type: Number,
      default: null,
    },
    processingFeeAmount: {
      type: Number,
      default: null,
    },
    totalAmenityAmount: {
      type: Number,
      default: null,
    },
    baseFare: {
      type: Number,
      default: null,
    },
    discountPercent: {
      type: Number,
      default: null,
    },
    discountAmount: {
      type: Number,
      default: null,
    },
    alreadyBooked: {
      type: Boolean,
    },
    cancellationPolicy: {
      type: Boolean,
    },
    quote: {
      type: Object,
      default: () => {},
    },
    button: {
      type: Boolean,
    },
  },
  data() {
    return {
      customerAccountDefaults: null,
      bookingProtectionModalDetail: bookingProtectionModalDetail,
    }
  },
  computed: {
    sevenDaysBeforeFirstTrip() {
      const sevenDays = Duration.fromObject({ days: -7 })
      const dt = DateTime.fromISO(
        this.quote?.trips?.[0]?.stops[0].pickup_datetime
      ).plus(sevenDays)
      return dt.toLocaleString({ month: 'long', day: 'numeric' })
    },
    dueDate() {
      const then = DateTime.fromISO(
        this.quote?.trips?.[0]?.dueDate
      ).toLocaleString({
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
      return then || ''
    },
    isDriverHotelRequired() {
      return this.quote?.trips?.some((trip) =>
        trip?.risks?.some(
          (risk) => risk?.riskTypeId === RiskTypeId.DriverHotelRisk
        )
      )
    },
    paidAmenities() {
      const tripAmenities = this.quote?.trips?.[0]?.tripAmenities || []
      return tripAmenities
        .filter((amenity) => amenity.price > 0)
        .map((amenity) => amenity.label)
        .join(', ')
    },
    paymentTermDays() {
      return this.customerAccountDefaults?.paymentTermsDays
        ? `(net ${this.customerAccountDefaults.paymentTermsDays})`
        : ''
    },
  },
  async mounted() {
    this.loadCustomerAccountDefaults()
  },
  methods: {
    async loadCustomerAccountDefaults() {
      const customerId = this.quote?.trips?.[0]?.customerId
      if (!customerId) {
        return
      }
      const defaultsResponse = await customers.getCustomerAccountDefaultsByCustomer(
        customerId
      )
      this.customerAccountDefaults = defaultsResponse.data
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.learn-more-text {
  color: $blue !important;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.primary-text {
  font-size: 18px;
  color: $primary;
}

.discount-line-item {
  color: $primary !important;
}

.booking-protection-header {
  font-size: 16px;
  font-weight: bold;
}

.charterup-primary-btn {
  background-color: $primary !important;
  color: white;
  height: 50px;
  border-radius: 8px !important;
}
</style>
