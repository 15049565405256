<template>
  <v-layout v-if="company" row wrap align-start justify-space-between>
    <v-flex xs12 sm8>
      <v-layout row wrap align-start>
        <v-flex xs12>
          <h2 class="border-b-0">
            {{ company.companyDBAName }}
          </h2>
        </v-flex>
        <v-flex v-if="$vuetify.breakpoint.smAndUp" shrink>
          <CharterUPRating
            v-if="company.rating"
            :style="{ 'margin-top': '1px' }"
            :rating="company.rating"
          />
        </v-flex>
        <v-flex
          v-if="company.preferred && $vuetify.breakpoint.smAndUp"
          shrink
          class="margin-x-2"
        >
          •
        </v-flex>
        <v-flex v-if="company.preferred" shrink class="margin-l-0">
          <InfoModal :modal-detail="certifiedPartnerModalDetail">
            <template #activator="{ on, attrs }">
              <div class="cursor-pointer" v-bind="attrs" v-on="on">
                <CertifiedPartnerBadge />
              </div>
            </template>
          </InfoModal>
        </v-flex>
        <v-flex
          v-if="website && (company.preferred || $vuetify.breakpoint.smAndUp)"
          shrink
          class="margin-x-2"
        >
          •
        </v-flex>
        <v-flex v-if="website" shrink align-self-start>
          <a
            id="provider-detail-link-website"
            :href="getUrl(website)"
            target="_blank"
            class="d-flex align-center justify-center"
          >
            <CRIcon
              color="black"
              class="margin-r-1 margin-t-n2 cursor-default"
              width="16px"
              height="16px"
              view-box="0 0 24 24"
            >
              open_in_new
            </CRIcon>
            <span class="text-gray child margin-b-1">Website</span>
          </a>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { alwaysShowTenthsPlace } from '@/utils/string'
import CertifiedPartnerBadge from './CertifiedPartnerBadge.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModal'
import InfoModal from './InfoModal.vue'

export default {
  components: {
    CertifiedPartnerBadge,
    InfoModal,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      certifiedPartnerModalDetail,
    }
  },
  computed: {
    formattedRating() {
      return alwaysShowTenthsPlace(this.company.rating)
    },
    website() {
      return this.company.customerFacingUrl
    },
  },
  methods: {
    getUrl(url) {
      if (!url.match(/^https?:\/\//i)) {
        url = `http://${url}`
      }
      return url
    },
  },
}
</script>
