<template>
  <div>
    <h2 style="margin-top: 36px; margin-left: 4px; border: none;">
      More bus companies nearby
    </h2>
    <v-slide-group
      v-if="$cr.breakpoint.lgAndUp && bids"
      style="margin-top: 18px"
      multiple
      show-arrows
      :style="computedSliderClass"
    >
      <v-slide-item v-for="(bid, i) in bids" :key="i">
        <BidCard
          :id="`provider-bidcard-${i}`"
          stacked
          style="padding-right: 8px; padding-left: 8px; margin-top: 13px;"
          :imgwidth="400"
          :bid="bid"
          :go-to="{
            name: 'charterup-provider-detail',
            params: {
              quoteId: quoteId,
              providerId: bid.companyId,
            },
          }"
        />
      </v-slide-item>
    </v-slide-group>
    <div v-else style="margin-top: 18px; margin-bottom: 80px">
      <BidCard
        v-for="(bid, i) in bids"
        :id="`provider-bidcard-${i}`"
        :key="i"
        :stacked="!$cr.breakpoint.smAndUp"
        :button="$cr.breakpoint.md"
        style="margin-bottom: 12px"
        :imgwidth="$cr.breakpoint.smAndUp ? 220 : 340"
        :bid="bid"
        :go-to="{
          name: 'charterup-provider-detail',
          params: {
            quoteId: quoteId,
            providerId: bid.companyId,
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import BidCard from '@/components/charterup/BidCard.vue'

export default {
  components: {
    BidCard,
  },
  props: {
    quoteDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      isNewerFleetEnabled: false,
      isHasTrackingEnabled: false,
    }
  },
  computed: {
    customerId() {
      return this.quoteDetail?.customer?.customerId
    },
    bids() {
      return this.quoteDetail?.bids
    },
    quoteId() {
      return this.quoteDetail?.quoteId
    },
    computedSliderClass() {
      return this.bids.length > 3
        ? {
            'margin-right': '-48px',
            'margin-left': '-48px',
            'margin-top': '6px',
          }
        : {}
    },
  },
}
</script>

<style lang="scss" scoped>
.BidCarouselStyle {
  margin-bottom: 56px;
}
</style>
