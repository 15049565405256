<template>
  <div class="text-center position-absolute z-1 right-25 bottom-10">
    <v-dialog
      :value="value"
      :content-class="$vuetify.breakpoint.xs ? 'fullscreen-dialog' : ''"
      fullscreen
      width="600"
      @input="$emit('input', $event)"
    >
      <v-card tile class="margin-t-5">
        <v-layout
          row
          class="text-gray"
          :style="{ 'padding-top': '10px', 'margin-right': '10px' }"
        >
          <v-spacer></v-spacer>
          <v-btn
            id="provider-button-close-buttons"
            color="gray"
            icon
            small
            @click="$emit('input', false)"
          >
            <CUIcon view-box="0 0 24 24">close</CUIcon>
          </v-btn>
        </v-layout>
        <Carousel
          :value="index"
          class="margin-t-5"
          :photos="photos"
          @input="$emit('update:index', $event)"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Carousel from '@/components/charterup/Carousel.vue'
export default {
  components: {
    Carousel,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    photos: {
      type: Array,
      required: true,
    },
  },
}
</script>
