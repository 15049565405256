<template>
  <div>
    <div
      v-for="(vehicleType, vehicleTypeIndex) in vehicleTypes"
      :key="vehicleTypeIndex"
      class="padding-t-4"
    >
      <h3>{{ vehicleType.label }}</h3>
      <ProviderInfoVehicleTypePhotos
        :photos="vehicleType.photos"
        @open-modal="openModal"
      />
      <ProviderInfoVehicleTypeAmenities :amenities="vehicleType.amenities" />
      <v-divider class="margin-t-4" />
    </div>

    <ProviderInfoVehiclePhotoCarousel
      v-if="companyPhotos && companyPhotos.length > 0"
      v-model="imageModal"
      :photos="companyPhotos"
      :index="carouselIndex"
      @update:index="carouselIndex = $event"
    />
  </div>
</template>

<script>
import ProviderInfoVehicleTypePhotos from '@/components/charterup/ProviderInfoVehicleTypePhotos.vue'
import ProviderInfoVehicleTypeAmenities from '@/components/charterup/ProviderInfoVehicleTypeAmenities.vue'
import ProviderInfoVehiclePhotoCarousel from '@/components/charterup/ProviderInfoVehiclePhotoCarousel.vue'
import { hostBaseUrl } from '@/utils/env'

export default {
  components: {
    ProviderInfoVehicleTypePhotos,
    ProviderInfoVehiclePhotoCarousel,
    ProviderInfoVehicleTypeAmenities,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      carouselIndex: 0,
      imageModal: false,
    }
  },
  computed: {
    companyPhotos() {
      return this.company.vehiclePhotos
        .slice()
        .sort((a, b) => a.orderIndex - b.orderIndex)
        .map((vehiclePhoto) => {
          const path = vehiclePhoto.imagePath
          const imagePath = path?.[0] !== '/' ? path : `${hostBaseUrl()}${path}`
          return {
            ...vehiclePhoto,
            imagePath,
          }
        })
    },
    vehicleTypes() {
      const vehicleTypeMap = {}
      const vehicleTypeAmenities = this.company.vehicleTypeAmenities
      const vehiclePhotoTypes = this.companyPhotos.reduce((acc, photo) => {
        if (photo.vehicleTypeId) {
          acc[photo.vehicleTypeId] = acc[photo.vehicleTypeId] || []
          acc[photo.vehicleTypeId].push(photo)
        }
        return acc
      }, {})

      for (const vehicleTypeId of Object.keys(vehiclePhotoTypes)) {
        if (!vehicleTypeMap[vehicleTypeId]) {
          vehicleTypeMap[vehicleTypeId] = {}
        }
        const amenities = vehicleTypeAmenities[vehicleTypeId]
        vehicleTypeMap[vehicleTypeId].amenities = amenities || []

        const photos = this.companyPhotos.filter(
          (photo) => photo.vehicleTypeId === Number(vehicleTypeId)
        )
        vehicleTypeMap[vehicleTypeId].photos = photos || []

        const label = photos?.[0]?.vehicleTypeDTO?.label
        vehicleTypeMap[vehicleTypeId].label = label
      }

      return Object.values(vehicleTypeMap)
    },
  },
  methods: {
    openModal(image) {
      this.imageModal = true
      this.carouselIndex = this.companyPhotos.indexOf(image)
    },
  },
}
</script>
